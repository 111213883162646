function  showCards(){
    var divs = document.getElementsByClassName("amp-dc-card journal-small-270x181");
    var lastChild = divs.length;
    var showcardlist=document.getElementsByClassName("show3cards").length;
    if(lastChild>6 && lastChild%3==0){
        for (var i = 6+showcardlist; i < 9+showcardlist; i++){           
            if(divs.length-6 -showcardlist==3){              
                divs[i].className += " show3cards";
                document.getElementsByClassName('journal-morestories-button')[0].style.display = 'none';             
            } else{
                divs[i].className += " show3cards";
            }         
        }
    }   
}