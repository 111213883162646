function gtmPromoclicks(gtmId, gtmName, gtmCreative, gtmPosition){
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
    'event': 'eec.promotionClick',
    'ecommerce': {
        'promoClick': {
        'promotions': [                     
        {
            'id': gtmId,            
            'name':gtmName,
            'creative': gtmCreative,
            'position': gtmPosition

        }]
        }
    }
    });
}

function gtmHomepageBannerclicks(gtmType, bannerText, clickText){  
    console.log(bannerText+"-"+clickText);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
    'event': 'eec.homepageBannerClick',
    'eventCategory': 'Ecommerce',
    'eventAction':gtmType,
    'eventLabel':bannerText+"-"+clickText
    });
}
