function constructSlickSection(data){
    const desktopCount = (data && data.slidecount) ? data.slidecount:1;
    const dots =(data && data.slickdots) ? data.slickdots:false;

    $('.more-stories-carousel').slick({
        slidesToShow: desktopCount,
        slidesToScroll: 1,
        dots:dots,
        arrows: true,
        responsive: [
          {
            breakpoint: 874,
            settings: {
              arrows: true,
              slidesToShow:desktopCount,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              arrows: true,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    });
}  
    function bindScroll(){
        
        var lastId;
        var topMenu = $("#mainNav");
        var topMenuHeight = topMenu.outerHeight();
      // All list items
       
        var menuItems = topMenu.find("a");
      // Anchors corresponding to menu items
         var scrollItems = menuItems.map(function(){
         var item = $($(this).attr("href"));
           if (item.length) { return item; }
          });
         
         var fromTop = $(this).scrollTop()+topMenuHeight;
        
        // Get id of current scroll item
        var cur = scrollItems.map(function(){
            
          if ($(this).offset().top < fromTop)
          
            cur=this;
            return this;
           
            
        });
     
        // Get the id of the current element
        cur = cur[cur.length-1];
        var id = cur && cur.length ? cur[0].id : "";
 
        if (lastId !== id) {
            lastId = id;
            // Set/remove active class
            
            menuItems.parent().removeClass("active").end().filter('[href='+"'#"+id+"'"+']').parent().addClass("active");
            
        }
     }
     
     // fixed menu
 
     function invokeFunction(){
         var lastId;
         var topMenu = $("#mainNav");
         var topMenuHeight = topMenu.outerHeight();
         // All list items
         if(topMenu){
         var menuItems = topMenu.find("a");
         // Anchors corresponding to menu items
         var scrollItems = menuItems.map(function(){
           var item = $($(this).attr("href"));
            if (item.length) { return item; }
         });
        
        // Bind click handler to menu items so we can get a fancy scroll animation
        menuItems.click(function(e){
         
          var href = $(this).attr("href"),
              offsetTop = href === "#" ? 0 : $(href).offset().top-topMenuHeight+1;
          $('html, body').stop().animate({ 
              scrollTop: offsetTop
          }, 850); // speed of scroll
          
          event.preventDefault();
          return false;
        });
        $(window).scroll(function(){
        var fromTop = $(this).scrollTop()+topMenuHeight;
        var cur = scrollItems.map(function(){
                  if ($(this).offset().top < fromTop)
                     return this;
                 });
                 // Get the id of the current element
                 cur = cur[cur.length-1];
                 var id = cur && cur.length ? cur[0].id : "";
         
        if (lastId !== id) {
         lastId = id;
         // Set/remove active class
        
         menuItems.parent().removeClass("active").end().filter('[href='+"'#"+id+"'"+']').parent().addClass("active");
         
           }
         });
         if ($(window).width() < 600) {
             $('#scroll-next').click(function() {
                 $('html,body').animate({
                     scrollTop: $('#mobileBtn').offset().top
                 }, 1000);
             });
         }
         else {
             $('#scroll-next').click(function() {
                 $('html,body').animate({
                     scrollTop: $('#theCreator').offset().top-60
                 }, 1000);
             });
         }
         
         bindScroll();
         createSticky($(".scroll-nav"));
               createSection($(".section-bcc"));
            createActivelink($(".active-nav-link"));
     //$(function(){
         return false;
        }
     }
 
 
     function createSticky(sticky) {
      
     
         if (typeof sticky !== "undefined") {
          if (sticky.offset()) {
             var	pos = sticky.offset().top,
                     win = $(window);
                   
             win.on("scroll", function() {
                 win.scrollTop() >= pos ? sticky.addClass("fixed") : sticky.removeClass("fixed");
             });
            }			
         }
     }
 
 
 
     function createSection(sections) {
 
         if (typeof sections !== "undefined") {
           if(sections.offset()){
             var	pos = sections.offset().top-61,
                     win = $(window);
 
             win.on("scroll", function() {
                 win.scrollTop() >= pos ? sections.addClass("top") : sections.removeClass("top");
             });			
         }
        }
         return false;
     }
 
 
     function createActivelink(activelink) {
 
         if (typeof activelink !== "undefined") {
           if(activelink.offset()){
             var	pos = activelink.offset().top,
                     win = $(window);
 
             win.on("scroll", function() {
                 win.scrollTop() >= pos ? activelink.removeClass("active-nav-link") : activelink.addClass("active-nav-link");
             });	
             return false;	
            }	
         }
         return false;
     }
 
     
     /* mobile nav */
     function constructMobData(){
         
   
          if ($(window).width() < 600) {
            $('#scroll-next').click(function() {
               $('html,body').animate({
                   scrollTop: $('#mobileBtn').offset().top
             }, 1000);
            });
           } else {
           $('#scroll-next').click(function() {
              $('html,body').animate({
                  scrollTop: $('#theCreator').offset().top-60
              }, 1000);
           });
          }
             $(window).scroll(function() {
             if ($(this).scrollTop() > 200) {
                 $('.mobile-nav').fadeIn(500);
             } else {
                 $('.mobile-nav').fadeOut(500);
             }
             });
 
         $('.mobile-nav > .open').click(function() {
             $("nav.scroll-nav").css('display', 'flex');
             $('html').css({
                 overflow: 'hidden'
             });
             $(".mobile-nav > .open").css('display', 'none');
             $(".mobile-nav > .closenav").css('display', 'block');
         })
         $('.mobile-nav > .closenav').click(function() {
             $("nav.scroll-nav").css('display', 'none');
             $('html').removeAttr('style');
             $(".mobile-nav > .open").css('display', 'block');
             $(".mobile-nav > .closenav").css('display', 'none');
         })
         $('nav li a').click(function() {
           
             $("nav.scroll-nav").css('display', 'none');
             $('html').removeAttr('style');
             $(".mobile-nav > .open").css('display', 'block');
             $(".mobile-nav > .closenav").css('display', 'none');
             
         })
 
         
         
         // Pre-loader
        //  $(window).load(function() {
        //      $('#preloader-overlay').fadeOut('slow', function() {
        //          $(this).remove();
        //      });
        //  });
         
     }
     
     
     
     
     