function loadYoutube(){ 
 
    var options="a.play-vid";
    $(options).autoplay = false; 
    $("a.play-vid-2").autoplay = false;

    var YouTubePopUpOptions = $.extend({
            autoplay: 1
    }, options );
    var youtubeLink = $(options).attr('href');
    if( youtubeLink.match(/(youtube.com)/) ){
                        var split_c = "v=";
                         var split_n = 1;
                     }
                   
                     if( youtubeLink.match(/(youtu.be)/) || youtubeLink.match(/(vimeo.com\/)+[0-9]/) ){
                       var split_c = "";
                         var split_n = 3;
                     }
                   
                     if( youtubeLink.match(/(vimeo.com\/)+[a-zA-Z]/) ){
                         var split_c = "";
                         var split_n = 5;
                     }
                     
                     
                     
                     
                     
                     var cleanVideoID;
                     if( youtubeLink.match("/(youtu.be)/")){
                        var cleanVideoID = youtubeLink.replace('https://youtu.be', '');
                     } else if( youtubeLink.match("/(vimeo.com\/)+[a-zA-Z]/")){
                        var cleanVideoID = youtubeLink.replace('https://vimeo.com/', '');
                     }
                    if( youtubeLink.match(/(youtu.be)/) || youtubeLink.match(/(youtube.com)/) ){
                       var videoEmbedLink = "https://www.youtube.com/embed"+cleanVideoID+"?rel=0&showinfo=0&color=white&wmode=opaque&autoplay="+YouTubePopUpOptions.autoplay+"";
                     }
        
                     if( youtubeLink.match(/(vimeo.com\/)+[0-9]/) || youtubeLink.match(/(vimeo.com\/)+[a-zA-Z]/) ){
                         var videoEmbedLink = "https://player.vimeo.com/video"+cleanVideoID+"?rel=0&showinfo=0&color=white&wmode=opaque&autoplay="+YouTubePopUpOptions.autoplay+"";
                 }
               
        
                     $("body").append('<div class="YouTubePopUp-Wrap YouTubePopUp-animation"><div class="YouTubePopUp-Content"><iframe src="'+videoEmbedLink+'" allowfullscreen></iframe><span class="close-video-btn">Close X</span></div></div>');
                    
                     $('html').css({
                          overflow: 'hidden'
                   });
                             
                     if( $('.YouTubePopUp-Wrap').hasClass('YouTubePopUp-animation') ){
                     setTimeout(function() {
                             $('.YouTubePopUp-Wrap').removeClass("YouTubePopUp-animation");
                         }, 600);
                     }
                 
                     $(".YouTubePopUp-Wrap, .YouTubePopUp-Close").click(function(){
                         $(".YouTubePopUp-Wrap").addClass("YouTubePopUp-Hide").delay(515).queue(function() { $(this).remove(); });
                         $('html').removeAttr('style');
                     });
                   
                   
                     
        
                     event.preventDefault();
    $(document).keyup(function(e) {

        if ( e.keyCode == 27 ){
            $('.YouTubePopUp-Wrap, .YouTubePopUp-Close').click();
        }

    });
    return false;

}



function multipleVideoLoad(){ 
 
    const options="a.play-vid";
    $(options).autoplay = false; 
    $("a.play-vid-2").autoplay = false;

    const YouTubePopUpOptions = $.extend({
            autoplay: 1
    }, options );
    const youtubeLink =event.currentTarget.href;
    if( youtubeLink.match(/(youtube.com)/) ){
                        var split_c = "v=";
                         var split_n = 1;
                     }
                   
                     if( youtubeLink.match(/(youtu.be)/) || youtubeLink.match(/(vimeo.com\/)+[0-9]/) ){
                       var split_c = "";
                         var split_n = 3;
                     }
                   
                     if( youtubeLink.match(/(vimeo.com\/)+[a-zA-Z]/) ){
                         var split_c = "";
                         var split_n = 5;
                     }
                     
                     
                     
                     
                     
                     var cleanVideoID;
                     if( youtubeLink.match("/(youtu.be)/")){
                        var cleanVideoID = youtubeLink.replace('https://youtu.be', '');
                     } else if( youtubeLink.match("/(vimeo.com\/)+[a-zA-Z]/")){
                        var cleanVideoID = youtubeLink.replace('https://vimeo.com/', '');
                     }
                    if( youtubeLink.match(/(youtu.be)/) || youtubeLink.match(/(youtube.com)/) ){
                       var videoEmbedLink = "https://www.youtube.com/embed"+cleanVideoID+"?rel=0&showinfo=0&color=white&wmode=opaque&autoplay="+YouTubePopUpOptions.autoplay+"";
                     }
        
                     if( youtubeLink.match(/(vimeo.com\/)+[0-9]/) || youtubeLink.match(/(vimeo.com\/)+[a-zA-Z]/) ){
                         var videoEmbedLink = "https://player.vimeo.com/video"+cleanVideoID+"?rel=0&showinfo=0&color=white&wmode=opaque&autoplay="+YouTubePopUpOptions.autoplay+"";
                 }
               
        
                     $("body").append('<div class="YouTubePopUp-Wrap YouTubePopUp-animation"><div class="YouTubePopUp-Content"><iframe src="'+videoEmbedLink+'" allowfullscreen></iframe><span class="close-video-btn">Close X</span></div></div>');
                    
                     $('html').css({
                          overflow: 'hidden'
                   });
                             
                     if( $('.YouTubePopUp-Wrap').hasClass('YouTubePopUp-animation') ){
                     setTimeout(function() {
                             $('.YouTubePopUp-Wrap').removeClass("YouTubePopUp-animation");
                         }, 600);
                     }
                 
                     $(".YouTubePopUp-Wrap, .YouTubePopUp-Close").click(function(){
                         $(".YouTubePopUp-Wrap").addClass("YouTubePopUp-Hide").delay(515).queue(function() { $(this).remove(); });
                         $('html').removeAttr('style');
                     });
                   
                   
                     
        
                     event.preventDefault();
    $(document).keyup(function(e) {

        if ( e.keyCode == 27 ){
            $('.YouTubePopUp-Wrap, .YouTubePopUp-Close').click();
        }

    });
    return false;

}